// Custom style here
/**
 * THIS STYLE IS FROM DEMO ONLY
 * You can remove this once you are familair with the template
 */
 // a {
 //    text-decoration: underline;
 // }

 // a:hover {
 //    color: #0275a8;
 //    text-decoration: underline;
 // }

 .demo-inline-wrapper {
    display: inline-block;
    margin-bottom: 30px;
    vertical-align: top;
}

.demo-inline-wrapper:not(:last-child) {
    margin-right: 30px;
}

.demo-inline-wrapper .form-group {
    margin: 0;
}

.btn-demo > .btn,
.btn-demo > .btn-group {
    margin: 0 5px 10px 0;
}

.color-demo {
    color: #fff;
    text-align: center;
    padding: 1.6rem;
    border-radius: 2px;
    margin-bottom: 2.2rem;
    box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.2);
}

.color-demo--light {
    color: #5E5E5E;
}

.color-demo__color {
    text-transform: uppercase;
    font-weight: 500;
}

.color-demo__hex {
    margin: 0.4rem 0;
}

.animation-demo .row {
    margin: 1.9rem -0.76rem 0;
}

.animation-demo .col-sm-6 {
    padding: 0 0.76rem;
    margin-bottom: 0.9rem;
}

.animation-demo .card-block {
    padding-bottom: 1.15rem;
}

.animation-demo .card-block > img {
    position: relative;
    width: 100%;
    z-index: 1;
    border-radius: 2px;
    box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.2);
}

.animation-demo .card-block .btn {
    width: 100%;
}

.icons-demo .card-block .zmdi {
    font-size: 1.7rem;
    vertical-align: bottom;
    margin-right: 1rem;
}

.icons-demo .card-block .col-sm-4 {
    padding: 0.8rem 1.1rem;
}

.icons-demo .card-block .col-sm-4:hover {
    background: rgba(0, 0, 0, 0.03);
}

.card-demo {
    vertical-align: top;
    max-width: 450px;
    width: 100%;
    margin: 0 30px 0 0;
    display: inline-block;
}

.dropdown-demo {
    margin: 10px 10px 0 0;
    display: inline-block;
}

.modal-demo {
    background-color: #eee;
}

.modal-demo .modal {
    z-index: 0;
    position: relative;
    display: block;
}

.submit-loader {
    @extend .page-loader;
    background-color: transparent;
    display: none;
    
    .page-loader__spinner {
        background-color: white;
        border-radius: 10px;
    }
}

.content__title {
    padding-left: 5px;
}

.content__title > h1 {
    font-size: 2rem;
}

.content__title > small {
    font-size: 1.15rem;
}

i.zmdi-black {
    color: #ff6b68;
}

.profile__info > h3 {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}

.blue_edit {
    color: #03A9F4;
}

// .inputtext-editable {
//     label {
//         width:240px;
//     }
    
//     input {
//         display:inline; width: 300px;
//     }
    
//     select {
//         display: inline;
//         width: 300px;
//     }
    
//     i.form-group__bar {
//         width: 300px;
//         margin-left: 240px;
//     }
    
    .btn-edit {
        color: #03A9F4;
        font-size: 1.2rem;
        cursor: pointer;
        // margin-left: 30px;
    }
    
    .btn-save {
        color: #32c787;
        font-size: 1.2rem;
        cursor: pointer;
        // margin-left: 30px;
    }
    
    .btn-cancel {
        color: #ff6b68;
        font-size: 1.2rem;
        cursor: pointer;
        // margin-left: 15px;
    }
// }

.icon-offer-extended {
    color: gold;
}
.icon-available {
    color: green;
}
.icon-unavailable {
    color: red;
}

.offer {
    max-width: 400px;
}

.hidden {
    display: none;
}

.error-bar {
    min-height: 30px;
}

a.nodeco {
    text-decoration: none;
    color: inherit;
}

.toggle-switch-label {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 20px;

    & label {
        position: absolute;
        top: 0;
        left: 0;
    }
}


// 
.progress-section {
    padding: 5px 0;
    background-color: #F5F6F7;
    box-shadow: 2px 2px 5px #D0D0D0;

    & .progress-section--icon {
        display: table-cell;
        padding: 2px 8px 2px 16px;
        color: #fff;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        width: 80px;

        &.pass {
            background-color: #32c787;
        }

        &.nopass {
            background-color: #ed1c24;
        }
    }

    & .progress-section--message {
        display: table-cell;
        vertical-align: middle;
        padding-left: 40px;

        &.pass {
            color: #32c787;
        }

        &.nopass {
            color: #ed1c24;
        }
    }
}

.form-group:not(.form-group--float) > label,
.form-group label {
    font-size: 1.1rem;
}

.form-group label {
    font-weight: normal;
}

a.certification-error {
    color: #ED2431 !important;
    text-decoration: underline !important;
}

.font-1-1 { font-size: 1.1em}
.profile-image { width:64px; height: 64px; }