.profile {
  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 25px;
    // text-align: center;
  }
}

.profile__img {
  padding: 25px 25px 30px;
  position: relative;

  img {
    max-width: 120px;
  }

  @include media-breakpoint-down(sm) {
    text-align: center;

    img {
      margin: -55px 0 -10px;
      width: 120px;
      border: 5px solid $white;
      border-radius: 50%;
    }
  }
}

.profile__img__edit {
  position: absolute;
  font-size: 1.2rem;
  top: 15px;
  left: 15px;
  background-color: rgba($black,0.4);
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  color: $white;

  &:hover {
    background-color: rgba($black,0.65);
    color: $white;
  }
}

.profile__info {
  width: 100%;

  & .profile__info_name {
    border-bottom: 1px solid $gray-lighter;
    padding: 0 20px 20px;
  }

  & .profile__info_offer {
    border-top: 1px solid $gray-lighter;
  }
}

.img-circle {
  border-radius: 50%;
}
