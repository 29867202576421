.list {
  list-style: none;
  padding-left: 0;

  & > li {
    &:before {
      font-family: $font-family-icon;
      margin-right: 1.1rem;
    }
  }
}

.list--star > li:before {
  content: '\f27d';
}

.list--check > li:before {
  content: '\f26b';
}

.list--dot > li:before {
  content: '\f26f';
}

.process-list {
  list-style: none;
  padding-left: 0;

  & li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 40px;

    &:before {
      font-family: 'Material-Design-Iconic-Font';
      content: '\f26c';
      color: $gray;
      position: absolute;
      left: 0;
      top: 0;    
    }

    &.active:before {
      color: $red;
    }
  }
}