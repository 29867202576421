
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
// @import "variables";

// Bootstrap
// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Variables
@import "../template/scss/variables";

// Mixins
@import "../template/scss/mixins";


// Bootstrap
@import "../template/scss/bootstrap-imports/bootstrap-imports";
//
// // Bootstrap overrides
@import "../template/scss/bootstrap-overrides/dropdown";
@import "../template/scss/bootstrap-overrides/media";
@import "../template/scss/bootstrap-overrides/progress";
@import "../template/scss/bootstrap-overrides/card";
@import "../template/scss/bootstrap-overrides/buttons";
@import "../template/scss/bootstrap-overrides/tables";
@import "../template/scss/bootstrap-overrides/forms";
@import "../template/scss/bootstrap-overrides/input-groups";
@import "../template/scss/bootstrap-overrides/custom-forms";
@import "../template/scss/bootstrap-overrides/pagination";
@import "../template/scss/bootstrap-overrides/alerts";
@import "../template/scss/bootstrap-overrides/close";
@import "../template/scss/bootstrap-overrides/breadcrumbs";
@import "../template/scss/bootstrap-overrides/accordion";
@import "../template/scss/bootstrap-overrides/carousel";
@import "../template/scss/bootstrap-overrides/modal";
@import "../template/scss/bootstrap-overrides/popovers";
@import "../template/scss/bootstrap-overrides/tabs";
@import "../template/scss/bootstrap-overrides/tooltip";
//
//
// // Core styles
@import "../template/scss/fonts";
@import "../template/scss/generics";
@import "../template/scss/base";
@import "../template/scss/list";
@import "../template/scss/layout";
@import "../template/scss/misc";
@import "../template/scss/header";
@import "../template/scss/footer";
@import "../template/scss/sidebar";
@import "../template/scss/chat";
@import "../template/scss/charts";
@import "../template/scss/toggle-switch";
@import "../template/scss/listview";
@import "../template/scss/page-loader";
@import "../template/scss/profile";
@import "../template/scss/photos";
@import "../template/scss/contacts";
@import "../template/scss/groups";
@import "../template/scss/messages";
@import "../template/scss/pricing-table";
@import "../template/scss/invoice";
@import "../template/scss/login";
@import "../template/scss/todo";
@import "../template/scss/notes";
@import "../template/scss/theme";
@import "../template/scss/ie-warning";
@import "../template/scss/error";
@import "../template/scss/spacing";

// Widgets
@import "../template/scss/widgets/past-days";
@import "../template/scss/widgets/visitors";
@import "../template/scss/widgets/pie-charts";
@import "../template/scss/widgets/quick-stats";
@import "../template/scss/widgets/photos";
@import "../template/scss/widgets/ratings";
@import "../template/scss/widgets/profile";
@import "../template/scss/widgets/contact";
@import "../template/scss/widgets/signups";


// Vendor overrides
@import "../template/scss/vendor-overrides/flot";
@import "../template/scss/vendor-overrides/sparklines";
@import "../template/scss/vendor-overrides/select2";
@import "../template/scss/vendor-overrides/dropzone";
@import "../template/scss/vendor-overrides/nouislider";
@import "../template/scss/vendor-overrides/easy-pie-charts";
@import "../template/scss/vendor-overrides/data-tables";
@import "../template/scss/vendor-overrides/flatpickr";
@import "../template/scss/vendor-overrides/bootstrap-colorpicker";
@import "../template/scss/vendor-overrides/trumbowyg";
@import "../template/scss/vendor-overrides/fullcalendar";
@import "../template/scss/vendor-overrides/sweetalert2";
@import "../template/scss/vendor-overrides/lightgallery";
@import "../template/scss/vendor-overrides/jquery.scrollbar";
@import "../template/scss/vendor-overrides/Waves";

@import "../vendors/bower_components/material-design-iconic-font/scss/_material-design-iconic-font";
@import "../vendors/bower_components/animate.css/_animate";
@import "../vendors/bower_components/jquery.scrollbar/sass/jquery.scrollbar";
@import "../vendors/bower_components/fullcalendar/dist/fullcalendar";
@import "../vendors/bower_components/sweetalert2/dist/sweetalert2.min.css";
@import "../vendors/bower_components/select2/dist/css/select2.min.css";
@import "../vendors/bower_components/flatpickr/dist/flatpickr.min.css";
@import "../vendors/bower_components/dropzone/dist/dropzone.css";
@import "../vendors/bower_components/air-datepicker/dist/css/datepicker.min.css";

// Custom Stylesheet
@import "style"
